import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { GlobeIcon } from '@heroicons/react/solid';
import Special from '../components/Special';
import Divider from '../components/Divider';
import SEO from '../components/SEO';

export default function OurSchoolsPage({ path }) {
  const { schools } = useStaticQuery(graphql`
  query {   
    schools:allSanitySchool {
    nodes {
      image {
        asset {
          gatsbyImageData
        }
      }
      address {
        state
        street
        zip
        city
        comment
      }
      id
      name
      phone
      slug {
        current
      }
    }
  }
  }
`);
  return (
    <>
      <SEO title="Schools" description="Find your local Yuan Yen Do Karate Dojo and get directions." />
      <div className="px-4 pt-16 mx-auto mb-8 max-w-7xl sm:px-6">
        <div className="text-center">
          <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block">Get to know</span>
            <span className="block text-blue-600">our dojos</span>
          </h1>
          <p className="max-w-md mx-auto mt-3 text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Our schools are located around the south shore area. The dojo is padded are
            caparted with large mirrors so our members can focus on form. Each
            School has a waiting area and bathroom.
          </p>
        </div>
      </div>
      <div className="py-4" id="contact-us">
        {schools.nodes.map((school) => (
          <>
            <Divider title={school.name} />
            <section className="relative z-20 text-center bg-background-light xl:container xl:mx-auto md:text-left" key={school.id}>
              <div className="z-30 h-56 sm:h-72 ma md:absolute md:left-0 md:h-full md:w-1/2 filter inner-shadow drop-shadow-lg">
                <GatsbyImage
                  className="absolute inset-0 w-full h-full"
                  image={school.image.asset.gatsbyImageData}
                  alt={school.name}
                />
              </div>
              <div className="relative px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:py-16">
                <div className="mt-8 md:ml-auto md:w-1/2 md:pl-10">
                  <h2 className="text-base font-semibold tracking-wider uppercase text-secondary">Yuan Yen Do Karate Self Defense</h2>
                  <p className="mt-2 text-3xl font-extrabold tracking-tightm:text-4xl">{school.name}</p>
                  <p className="mt-3 text-lg">
                    {school.phone}
                  </p>
                  <p className="mt-3 text-lg">
                    {school.address.street}
                  </p>

                  <p className="text-lg">
                    (
                    {school.address.comment}
                    )
                  </p>
                  <p className="text-lg">
                    {school.address.city}
                    {' '}
                    {school.address.state}
                    {' '}
                    {school.address.zip}
                  </p>
                  <div className="mt-8 mb-8 md:mb-16">
                    <div className="inline-flex rounded-md shadow">
                      <Link
                        to={`/school/${school.slug.current}`}
                        className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-secondary hover:opacity-90"
                      >
                        <GlobeIcon className="w-6 h-6 pr-2" />
                        Get Directions
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        ))}
      </div>
      <Special path={path} />
    </>
  );
}
